/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import ThemeContext from "../context/theme"

import config from "../../config.json"
import { Link } from "gatsby"
import Helmet from "react-helmet"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <>
          <Helmet>
            <body className={theme.dark ? `dark` : "white"} />
          </Helmet>
          <Header siteTitle={config.name} />
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 960,
              padding: `0 1.0875rem 1.45rem`,
              backgroundColor: theme.dark ? `#212529` : "white",
            }}
          >
            <main>{children}</main>
            <footer
              style={{
                textAlign: "center",
                fontSize: "12px",
                marginTop: "80px",
              }}
            >
              <div
                style={{
                  backgroundColor: theme.dark ? `#212529` : "white",
                  color: theme.dark ? "white" : `#212529`,
                  textDecoration: `none`,
                  margin: `0 auto`,
                  maxWidth: 960,
                  padding: `0`,
                }}
              >
                <Link to="/">
                  <div
                    style={{
                      backgroundColor: theme.dark ? "white" : `#212529`,
                      color: theme.dark ? `#212529` : "white",
                      margin: "20px 0",
                      textDecoration: `none`,
                      borderRadius: "100%",
                      display: "inline-flex",
                      height: "32px",
                      width: "32px",
                      fontSize: "14px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {config.initials}
                  </div>
                </Link>
              </div>
              <p
                style={{
                  margin: `0`,
                }}
              >
                all works are public domain, and freely available to
                redistribute
              </p>
              <p
                style={{
                  margin: `0`,
                }}
              >
                made in amsterdam | by big fans of {config.author}
              </p>
            </footer>
          </div>
        </>
      )}
    </ThemeContext.Consumer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
