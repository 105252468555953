import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import config from "../../config.json"
import ThemeContext from "../context/theme"

const Header = () => (
  <ThemeContext.Consumer>
    {theme => (
      <header
        style={{
          backgroundColor: theme.dark ? `#212529` : "white",
          color: theme.dark ? "white" : `#212529`,
          textDecoration: `none`,
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Link to="/">
          <div
            style={{
              backgroundColor: theme.dark ? "white" : `#212529`,
              color: theme.dark ? `#212529` : `white`,
              margin: "20px 0",
              borderRadius: "100%",
              display: "inline-flex",
              height: "32px",
              width: "32px",
              fontSize: "14px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {config.initials}
          </div>
        </Link>
        <button
          style={{
            backgroundColor: theme.dark ? "white" : `#212529`,
            border: `1px solid ${theme.dark ? "white" : `#212529`}`,
            color: theme.dark ? `#212529` : `white`,
            outline: "0",
            cursor: "pointer",
          }}
          onClick={theme.toggleDark}
        >
          {theme.dark ? <span>Light</span> : <span>Dark</span>}
        </button>
      </header>
    )}
  </ThemeContext.Consumer>
)

export default Header
